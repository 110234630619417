<template>
  <div>
    <el-dialog
      :title="$t('vue_label_opportunity_product_addproduct')"
      :visible.sync="addmanualVisible"
      width="50%"
      :before-close="closeAddmanualDialog"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <!-- 当前价格手册： -->
      <div class="pop_tit">
        {{ $t("vue_label_pricebook_book") }}：{{ selectPrice }}
      </div>
      <div class="btn_box">
        <!-- 进行筛选 -->
        <el-button @click="filterBtn" type="primary" size="small">{{
          $t("label_emailtocloudcc_search")
        }}</el-button>
        <!-- 清除条件 -->
        <el-button @click="cleanFilter" type="primary" size="small">{{
          $t("label.delete.condition")
        }}</el-button>
      </div>
      <el-form
        :model="addmanualFormD"
        class="demo-ruleForm mar-t-20"
        size="mini"
        label-position="left"
      >
        <!-- 筛选器 -->
        <div
          class="screen_s"
          v-for="(ev, index) in addmanualFormD.list"
          :key="index"
        >
          <el-form-item>
            <span style="width: 20px">{{ index + 1 }}</span>
            <el-select
              v-model="ev.fieldId"
              :placeholder="$t('vue_label_commonobjects_detail_field')"
              class="select_one"
              filterable
              @change="selectChange($event, index)"
            >
              <el-option
                v-for="item in optionst_one"
                :key="item.id"
                :label="item.labelName"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 运算符 -->
            <el-select
              v-model="ev.op"
              :placeholder="$t('label.operator')"
              class="select_two"
              filterable
            >
              <el-option
                v-for="item in ev.optionst_two"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <EditableCell
              ref="editableCell"
              :inputType="ev.inputType"
              :options="selecArr"
              :value="ev.val[0]"
              :fieldId="ev.fieldId"
              :input-type="ev.inputType"
              :objid="ev.objid"
              :min="0"
              :max="1000000000000000000"
              @remoteSearch="remoteSearchs(ev, index)"
              @remoteMethodCell="remoteMethodCell(ev)"
              style="width: 38%; position: relative"
            ></EditableCell>
            <div style="width: 100px">
              <span
                class="filter_btn"
                @click="dels(index)"
                v-if="addmanualFormD.list.length !== 1"
                >-</span
              >
              <span
                class="filter_btn"
                @click="adds"
                v-if="
                  addmanualFormD.list.length - 1 === index &&
                  addmanualFormD.list.length <= 4
                "
                >+</span
              >
            </div>
          </el-form-item>
        </div>
      </el-form>
      <el-table
        ref="addmanualData"
        :data="addmanualData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 28px"
        :header-cell-style="{
          background: '#DDDBDA',
          color: '#333333',
          fontWeight: 400,
        }"
        class="add_table"
        :empty-text="$t('label.dashboard.noRecord')"
        height="200px"
      >
        <!-- 产品名称 -->
        <el-table-column prop="name" :label="$t('label.product.name')">
          <template slot-scope="scope">
            <span
              style="color: #006dcc; cursor: pointer"
              @click="selectPric(scope.row)"
              >{{ scope.row.name }}</span
            >
          </template>
        </el-table-column>
        <!-- 产品代码 -->
        <el-table-column
          :label="$t('label.product.code')"
          prop="createbyidccname"
        >
          <template slot-scope="scope">{{ scope.row.productcode }}</template>
        </el-table-column>
        <!-- 价目表价格 -->
        <el-table-column :label="$t('lable.product.plist')" prop="createdate">
          <template slot-scope="scope">{{ scope.row.listprice }}</template>
        </el-table-column>
        <!-- 供应商 -->
        <el-table-column
          :label="'供应商'"
          prop="createdate"
          v-if="realObjId == '2021127purchaseorder'"
        >
          <!-- <template slot-scope="scope">{{ scope.row.createbyidccname }}</template> -->
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
import * as createApi from "../api.js";
import inputType from "@/config/enumCode/inputType.js";
import operator from "@/config/enumCode/operator.js";
export default {
  components: {
    EditableCell: () => import("@/components/TablePanel/EditableCell"),
  },
  props: {
    //   当前价格手册
    selectPrice: {
      type: String,
      default: "",
    },
    // 筛选器数据
    addmanualForm: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // 对象前缀
    prefix:{
        type:String,
        default:""
    },
    //采购订单id
    realObjId:{
        type:String,
        default:""
    },
    // 选中价格
    pricSelect:{
        type:String,
        default:"",
    },
    currencyVal:{
        type:String,
        default:"",
    },
    searchProductsId:{
        type:String,
        default:"",
    },
    // 筛选字段
    optionst_one:{
        type:Array,
        default:()=>{
            return []
        }
    }
  },
  data() {
    return {
      addmanualFormD:this.addmanualForm,
      addmanualVisible: false,//弹框
      initData: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        type: 1,
        inputType: "input",
        optionst_two: [],
      },
      conditions:[],
      addmanualData:[],
      condition:[],//筛选数组
      fieldId:'',
      selecArr:[]
    };
  },
  mounted(){
  },
  methods: {
    closeAddmanualDialog() {
      this.cleanFilter();
      this.addmanualVisible = false;
    },
    //添加产品 新增
    adds() {
      this.dataId = 1;
      if (this.dataId < 5) {
        this.dataId += 1;
        this.addmanualFormD.list.push({
          ...this.initData,
          id: this.dataId,
        });
      } else {
        return;
      }
    },
    //添加产品 删除
    dels(n) {
      this.addmanualFormD.list.splice(n, 1);
      this.$refs.editableCell.splice(n, 1);
    },
    //添加产品 表格多选
    handleSelectionChange(val) {
      this.SelectChangeArr = [];
      val.map((item) => {
        this.SelectChangeArr.push(item.pbkid);
      });
    },
    addmanualClose() {
      this.cleanFilter();
      this.addmanualVisible = false
    },
    //筛选列表
    filterBtn() {
          let copyList = JSON.parse(JSON.stringify(this.addmanualFormD.list));
      copyList.forEach((item, i) => {
        delete copyList[i].type;
        delete copyList[i].inputType;
        delete copyList[i].id;
        delete copyList[i].optionst_two;
        if (item.seq) {
          delete copyList[i].seq;
        }
      });
      if (this.$refs.editableCell) {
        this.$refs.editableCell.forEach((item, index) => {
          copyList[index].val = item.editValue;
        });
      } else {
        copyList.forEach((item) => {
          item.val = item.val.value;
        });
      }
      this.condition = copyList;
      this.page = 1;
      this.searchProducts();
    },
    //添加产品 清除筛选器
    cleanFilter() {
      this.addmanualFormD.list = [
        {
          fieldId: "",
          op: "",
          val: [{ value: "" }],
          type: 1,
          id: 1,
          inputType: "input",
          optionst_two: [],
        },
      ];
      this.condition = [];
      this.searchProducts();
    },
    // 查询价格手册下的产品
    async searchProducts() {
      let data = {
        pricebookId: this.pricSelect,
        conditonstr:this.condition.length > 0 ? JSON.stringify(this.condition) : "", //条件, //条件
        keywords: this.keywords, //关键字
        page: 1,
        pageSize: 1000,
        currency: this.currencyVal,
        vendorid: this.$parent.$refs.baseForm.formData.vendorname,
        fieldId: this.searchProductsId
      };
      await createApi.searchProducts(data).then((res) => {
        this.addmanualData = res.data.dataList;
      });
    },
    remoteSearchs(item, index) {
        this.$emit('remoteSearchs',item, index)
    },
    remoteMethodCell(val){
        this.$emit('remoteMethodCell',val)
    },
    //添加产品 筛选下拉
    selectChange(val, index) {
      this.optionst_one.map((item) => {
        if (val === item.id) {
          this.addmanualFormD.list[index].op = "";
          this.addmanualFormD.list[index].optionst_two =
            operator.TYPE[item.type].getScreeningOpeTypes();
          this.addmanualFormD.list[index].val = {
            value: "",
          };
          this.addmanualFormD.list[index].inputType =
            inputType[item.type];
          this.selecArr = [];
          if (item.type === "ct") {
            //币种
            createApi.GetCurrencyInfo().then((res) => {
              res.data.forEach((item) => {
                (item.label = item.currencyname),
                  (item.value = item.currencycode);
              });
              this.selecArr = res.data;
            });
          } else if (item.type === "R") {
            //记录类型
            this.getRecordTypesFun();
          } else {
            createApi
              .GetViewGetSelectValue({
                fieldId: val,
              })
              .then((res) => {
                res.data.forEach((item) => {
                  (item.label = item.codekey), (item.value = item.codevalue);
                });
                this.selecArr = res.data;
              });
          }
        }
      });
    },
    //记录类型
    getRecordTypesFun() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      createApi.getRecordType(params).then((res) => {
        if (res.result) {
          res.data.recordTypeList.forEach((item) => {
            let obj = {
              label: item.name,
              value: item.name,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      });
    },
    //选中产品
    selectPric(row){
        this.$emit('selectPric',row,'search')
        this.cleanFilter();
    },
  },
};
</script>
<style scoped lang='scss'>
::v-deep .el-dialog{
  height: 75%;
}
::v-deep .el-table{
  height: calc(100% - 140px) !important;
}
.pop_tit {
  margin-bottom: 12px;
  font-size: 12px;
}
.btn_box {
  margin-bottom: 5px;
}
.btn_box {
  span {
    color: #006dcc;
    font-size: 12px;
    margin-right: 20px;
    cursor: pointer;
  }
}
.screen_s > div {
  margin-bottom: 0 !important;
}

.screen_s ::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
}
.el-form-item {
  margin-bottom: 0;
}
.screen_s ::v-deep .el-form-item--mini {
  line-height: 40px;
}
.select_one {
  width: 180px;
  margin: 6px;
}

.select_two {
  width: 140px;
  margin: 6px;
}

.select_three {
  width: 72px;
  margin: 6px;
}
.filter_btn {
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
}
::v-deep .el-dialog__body{
  height:90%
}
</style>